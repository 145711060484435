.card-item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span var(--card-item-sections, 3);
  gap: 10px 0;
  padding: 30px 20px 20px 20px;
  text-align: center;
  line-height: 1.3;
  font-size: 18px;

  @include min(tablet-landscape) {
    font-size: 20px;
  }

  span.font-weight-bold {
    font-weight: 740;
  }

  p {
    margin: 0 0 8px;
  }

  .button {
    margin-top: 10px;
    width: 100%;
  }

  &--top-image {
    --image-size: 280px;
    --image-shift: -200px;

    margin-top: calc(var(--image-shift) * -1);

    img {
      width: var(--image-size);
      height: var(--image-size);
    }

    .card-item-image:first-of-type {
      transform: translateY(var(--image-shift));
      position: relative;
      margin: 0 auto var(--image-shift);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      img {
        animation: jumping 760ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards normal;
        transform-origin: 50% 100%;
      }
    }
  }

  &--violet {
    background: linear-gradient(-48deg, #6A01D7 0%, #1F01B7 100%);
    border-radius: 32px;
  }

  &--sky-blue {
    background: linear-gradient(-48deg, #5AABEE 0%, #0481EF 100%);
    border-radius: 32px;
  }

  &--golden-sand {
    background-color: var(--c-golden-sand);
    color: var(--c-black);
    border-radius: 32px;
  }

  @keyframes jumping {
    20% { transform: translateY(1%) scaleY(0.95); }
    40% { transform: translateY(-7.5%) scaleY(1.05); }
    50% { transform: translateY(2.5%) scaleY(0.9); }
    70% { transform: translateY(-2%) scaleY(1); }
    80%, 100% { transform: translateY(0) scaleY(1); }
  }
}
