html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  background:
    url(../images/png/bg-stars.png)
    top left / 560px 580px
    repeat
    var(--c-midnight-blue);

  color: var(--c-white);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}
